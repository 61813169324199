import mixpanel from "mixpanel-browser";

mixpanel.init("ad1db66bdb416127ba9f4e4232fabe91")

const isProductionEnv = process.env.NODE_ENV === 'production';

const actions = {
    identify: (id) => {
      if (isProductionEnv) mixpanel.identify(id);
    },
    alias: (id) => {
      if (isProductionEnv) mixpanel.alias(id);
    },
    track: (name, props) => {
      if (isProductionEnv) mixpanel.track(name, props);
    },
    people: {
      set: (props) => {
        if (isProductionEnv) mixpanel.people.set(props);
      },
    },
  };

export const Mixpanel = actions