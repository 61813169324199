import React from 'react';
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react';
import { FeedbackFish } from '@feedback-fish/react';

export default function FeedbackBox({ user }) {
  return (
    <Flex
      flexDirection="column"
      w={['100%', '100%', '100%', '100%']}
      rounded="lg"
      border={'1px solid #e2e8f0'}
      p={4}
    >
      <Flex justifyContent="space-between" mb="20px">
        <Box>
          <Heading fontSize="normal" color="gray.700" fontWeight="semibold">
            Help us improve
          </Heading>
        </Box>
      </Flex>
      <Text fontSize={'md'} py={'2'}>
        Let us know what you think about the platform. Report a bug or request a
        feature.
      </Text>
      <Flex w="100%" mt="4">
        <FeedbackFish projectId="31db9b73671d16" userId={user.email}>
          <Button
            _hover={{ shadow: 'md' }}
            variant={'solid'}
            size="sm"
            textColor={'white'}
            bgColor={'gray.700'}
          >
            Send feedback
          </Button>
        </FeedbackFish>
      </Flex>
    </Flex>
  );
}
