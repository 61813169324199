import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Heading,
  Image,
  HStack,
  Button,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  VStack,
  Divider,
  Show,
  Tag,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import AuthService from '../services/auth-service';
import { Mixpanel } from '../utils/Mixpanel';

import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { AiOutlineDeploymentUnit, AiOutlineLineChart } from 'react-icons/ai';
import { ChevronRightIcon } from '@chakra-ui/icons';

import FeedbackBox from '../components/FeedbackBox.js';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import submissionsService from '../services/submissions-service.js';
import portfolioImage from '../images/resume.png';
import learningImage from '../images/learning.png';
import pathfinderImage from '../images/guidance.png';
import splitbee from '@splitbee/web';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';

export default function Dashboard({ user }) {
  const [isLoading, setIsLoading] = useState(true);
  const { email, firstname, is_mentor } = user;
  const [userTracks, setUserTracks] = useState('');
  const [primaryLearningPath, setPrimaryLearningPath] = useState(null);
  const [currentUserSkills, setCurrentUserSkills] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  Mixpanel.track('Home Page', { email: email });
  splitbee.track('Home Page', { email: email });
  const navigate = useNavigate();

  const calculateCompletedLessons = lessons => {
    return lessons.reduce((acc, lesson) => {
      if (lesson.hasCompleted) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    async function fetchLoggedInProfile() {
      const response = await AuthService.getLoggedInProfile(user.username);
      setUserTracks(response.tracks);
      setCurrentUserSkills(response.skills);
      response.tracks.length > 0 && setPrimaryLearningPath(response.tracks[0]);
      setIsLoading(false);
    }
    fetchLoggedInProfile();
  }, []);

  useEffect(() => {
    async function fetchSubmissions() {
      if (user) {
        const response = await submissionsService.listSubmissions(
          user.username
        );
        if (response.status === 200) {
          setSubmissions(response.data.submissions);
        }
      }
    }
    fetchSubmissions();
  }, [user?.username]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Home"
          isMentor={is_mentor}
          hideBecomeAMentorButton={false}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 md:px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 md:px-2 border-0">
                  <Flex flexDirection="column">
                    <Flex
                      p={2}
                      rounded={'lg'}
                      bgColor="white"
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mb={4}
                    >
                      <Box w="75%">
                        <Text fontSize={'2xl'} fontWeight="semibold">
                          Welcome, {`${firstname}!`}
                        </Text>
                        <Text
                          fontWeight="base"
                          fontSize={'md'}
                          className="text-gray-700"
                        >
                          Techstarta helps your learn and build your portfolio
                          to get a job in tech.
                        </Text>
                      </Box>
                    </Flex>

                    {/* Next steps */}

                    <HStack margin={'24px 0'}>
                      <AiOutlineLineChart className="w-6 h-6 text-gray-600" />
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        textColor={'gray.600'}
                      >
                        Choose your focus for today
                      </Text>
                    </HStack>

                    <SimpleGrid
                      spacing={4}
                      columns={[1, 2, 2, 3]}
                      justifyContent="space-evenly"
                    >
                      <LinkBox key={'portfolioBuilding'}>
                        <Box
                          border={'1px'}
                          borderColor="gray.100"
                          rounded={'lg'}
                          bgColor="white"
                          p={4}
                          _hover={{
                            borderColor: 'orange.100',
                            boxShadow: 'md',
                          }}
                          shadow="sm"
                        >
                          <Box pb={4}>
                            <Image
                              src={portfolioImage}
                              w={'14'}
                              alt="Portfolio"
                            />
                          </Box>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <LinkOverlay href="/challenges">
                                <Text fontSize={'lg'} fontWeight="semibold">
                                  Build your portfolio
                                </Text>
                              </LinkOverlay>
                              <Text
                                py={2}
                                fontWeight="base"
                                fontSize={'md'}
                                className="text-gray-700"
                              >
                                Complete challenges to build your portfolio. Get
                                feedback from mentors and improve your skills.
                              </Text>
                            </Box>
                          </Flex>
                          <Flex
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={4}
                          >
                            <Box>
                              {submissions.length > 0 ? (
                                <Text className="text-gray-700" fontSize={'md'}>
                                  <strong>{submissions.length}</strong>{' '}
                                  Challenges
                                </Text>
                              ) : (
                                <Text className="text-gray-700" fontSize={'md'}>
                                  Get started
                                </Text>
                              )}
                            </Box>
                            <Box>
                              <ArrowRightIcon className="w-6 h-6 text-gray-600" />
                            </Box>
                          </Flex>
                        </Box>
                      </LinkBox>
                      <LinkBox key={'learn'}>
                        <Box
                          border={'1px'}
                          borderColor="gray.100"
                          rounded={'lg'}
                          bgColor="white"
                          p={4}
                          shadow="sm"
                          _hover={{
                            borderColor: 'orange.100',
                            boxShadow: 'md',
                          }}
                        >
                          <Box pb={4}>
                            <Image src={learningImage} w={'14'} alt="Skills" />
                          </Box>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <LinkOverlay href="/skills">
                                <Text fontSize={'lg'} fontWeight="semibold">
                                  Learn the basics
                                </Text>
                              </LinkOverlay>
                              <Text
                                py={2}
                                fontWeight="base"
                                fontSize={'md'}
                                className="text-gray-700"
                              >
                                Learn the basics skills you need to solve
                                real-world tasks with our curated skill guides.
                              </Text>
                            </Box>
                          </Flex>
                          <Flex
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={4}
                          >
                            <Box>
                              {currentUserSkills.length > 0 ? (
                                <Text className="text-gray-700" fontSize={'md'}>
                                  <strong>{currentUserSkills.length}</strong>{' '}
                                  Skills
                                </Text>
                              ) : (
                                <Text className="text-gray-700" fontSize={'md'}>
                                  Get started
                                </Text>
                              )}
                            </Box>
                            <Box>
                              <ArrowRightIcon className="w-6 h-6 text-gray-600" />
                            </Box>
                          </Flex>
                        </Box>
                      </LinkBox>
                      <LinkBox key={'careerFit'}>
                        <Box
                          border={'1px'}
                          borderColor="gray.100"
                          rounded={'lg'}
                          bgColor="white"
                          p={4}
                          shadow="sm"
                          _hover={{
                            borderColor: 'orange.100',
                            boxShadow: 'md',
                          }}
                        >
                          <Box pb={4}>
                            <Image
                              src={pathfinderImage}
                              w={'14'}
                              alt="Skills"
                            />
                          </Box>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <LinkOverlay href="/quiz">
                                <Text fontSize={'lg'} fontWeight="semibold">
                                  Discover your career fit
                                </Text>
                              </LinkOverlay>
                              <Text
                                py={2}
                                fontWeight="base"
                                fontSize={'md'}
                                className="text-gray-700"
                              >
                                Take our career fit quiz to discover your
                                strengths and find the right career path for
                                you.
                              </Text>
                            </Box>
                          </Flex>
                          <Flex
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={4}
                          >
                            <Box>
                              <Text
                                textColor={'gray.600'}
                                fontSize={'md'}
                                fontWeight="semibold"
                              >
                                Take quiz
                              </Text>
                            </Box>
                            <Box>
                              <ArrowRightIcon className="w-6 h-6 text-gray-600" />
                            </Box>
                          </Flex>
                        </Box>
                      </LinkBox>
                    </SimpleGrid>

                    {/* Jump back in */}

                    <HStack
                      pt={'16'}
                      mb="20px"
                      color="#2d3748"
                      fontWeight="bold"
                    >
                      <RocketLaunchIcon className="w-6 h-6 text-gray-600" />
                      <Heading fontSize="lg" color="gray.600" fontWeight="bold">
                        Jump back In
                      </Heading>
                    </HStack>
                    <SimpleGrid spacing={'6'} columns={[1, 1, 1, 2]}>
                      <Flex w="100%" flexDirection="column" h="auto">
                        <Text
                          pb={4}
                          textColor={'gray.600'}
                          fontWeight="semibold"
                          fontSize="md"
                        >
                          Continue learning
                        </Text>{' '}
                        <VStack>
                          {currentUserSkills.map((skill, index) => (
                            <Flex
                              width={'100%'}
                              border={'1px'}
                              borderColor="gray.200"
                              shadow={'sm'}
                              rounded={'lg'}
                              key={skill.slug}
                              size="lg"
                              direction={'column'}
                              _hover={{ shadow: 'md', rounded: 'lg' }}
                              mb="2"
                            >
                              <Box p={4}>
                                <Text
                                  lineHeight={'4'}
                                  fontWeight="semibold"
                                  fontSize={'md'}
                                  textColor={'gray.600'}
                                >
                                  {skill.title}
                                </Text>
                                <Text
                                  lineHeight={'4'}
                                  fontSize="md"
                                  pt={2}
                                  textColor={'gray.600'}
                                >
                                  {skill.tier.label}
                                </Text>
                              </Box>
                              <Divider />
                              <Flex
                                p={4}
                                justifyContent="space-between"
                                alignItems={'center'}
                                bgColor={'gray.50'}
                              >
                                <Text lineHeight={'4'} textColor={'gray.600'}>
                                  {skill.total_points} points
                                </Text>
                                <Button
                                  _hover={{ shadow: 'md' }}
                                  bgColor={'gray.700'}
                                  textColor="white"
                                  size={'sm'}
                                  rounded={'full'}
                                  onClick={() =>
                                    navigate(`/skills/${skill.slug}/lessons`)
                                  }
                                >
                                  Resume
                                </Button>
                              </Flex>
                            </Flex>
                          ))}
                        </VStack>
                      </Flex>
                      <Flex w="100%" flexDirection="column" h="auto">
                        <Text
                          pb={4}
                          textColor={'gray.600'}
                          fontWeight="semibold"
                          fontSize="md"
                        >
                          Complete your challenges
                        </Text>
                        <VStack>
                          {submissions.map(submission => (
                            <LinkBox key={submission.id} w={'100%'}>
                              <Flex
                                key={submission.id}
                                w="100%"
                                justifyContent="space-between"
                                alignItems="center"
                                rounded="lg"
                                p={4}
                                cursor={'pointer'}
                                _hover={{
                                  bgColor: 'gray.50',
                                }}
                              >
                                <Flex alignItems="center">
                                  <Image
                                    src={submission.challenge.image}
                                    alt={submission.challenge.title}
                                    w="80px"
                                    h="80px"
                                    objectFit={'cover'}
                                    rounded="lg"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    ml="10px"
                                    justifyContent="center"
                                  >
                                    <LinkOverlay
                                      href={`/challenges/${submission.challenge.slug}`}
                                    >
                                      <Text
                                        fontSize="md"
                                        fontWeight="bold"
                                        color="#2d3748"
                                        noOfLines={1}
                                      >
                                        {submission.challenge.title}
                                      </Text>
                                    </LinkOverlay>

                                    <Wrap pt={2}>
                                      <WrapItem>
                                        <Tag
                                          variant={'outline'}
                                          borderColor={'gray.200'}
                                          fontSize="sm"
                                          textColor={'gray.600'}
                                          bgColor={'white'}
                                        >
                                          {submission.challenge.track}
                                        </Tag>
                                      </WrapItem>
                                    </Wrap>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  minWidth={'30%'}
                                  justifyContent="flex-end"
                                >
                                  <Show above="sm">
                                    <SubmissionStatus
                                      status={submission.status}
                                    />
                                  </Show>
                                  <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                </Flex>
                              </Flex>
                            </LinkBox>
                          ))}
                        </VStack>
                      </Flex>
                    </SimpleGrid>

                    {/* Expect more */}

                    <HStack pt={8} margin={'24px 0'}>
                      <AiOutlineDeploymentUnit className="w-6 h-6 text-gray-600" />
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        textColor={'gray.600'}
                      >
                        Expect More
                      </Text>
                    </HStack>

                    <SimpleGrid spacing={4} columns={[1, 2, 2, 3]}>
                      <Flex
                        flexDirection="column"
                        w={['100%', '100%', '100%', '100%']}
                        rounded="lg"
                        border={'1px solid #e2e8f0'}
                        p={4}
                      >
                        <Flex justifyContent="space-between" mb="20px">
                          <Box>
                            <Heading
                              pb={1}
                              fontSize="normal"
                              color="gray.700"
                              fontWeight="semibold"
                            >
                              Work Experience Program
                            </Heading>
                            <span className="bg-orange-100 text-orange-800 text-sm font-medium px-2 py-0.5 rounded">
                              Coming soon
                            </span>
                          </Box>
                        </Flex>
                        <Text fontSize={'md'} py={'2'}>
                          Collaborate with other learners in a mentor-led team.
                          Build real products.
                        </Text>
                        <Flex w="100%" mt="4"></Flex>
                      </Flex>
                      <Flex
                        flexDirection="column"
                        w={['100%', '100%', '100%', '100%']}
                        rounded="lg"
                        border={'1px solid #e2e8f0'}
                        p={4}
                      >
                        <Flex justifyContent="space-between" mb="20px">
                          <Box>
                            <Heading
                              pb={1}
                              fontSize="normal"
                              color="gray.700"
                              fontWeight="semibold"
                            >
                              Get matched with a job
                            </Heading>
                            <span className="bg-orange-100 text-orange-800 text-sm font-medium px-2 py-0.5 rounded">
                              Coming soon
                            </span>
                          </Box>
                        </Flex>
                        <Text fontSize={'md'} py={'2'}>
                          Our partner companies are looking for exceptional
                          talent. You could be one of them.
                        </Text>
                        <Flex w="100%" mt="4"></Flex>
                      </Flex>

                      <FeedbackBox user={user} />
                    </SimpleGrid>
                  </Flex>
                </div>
              </div>
            </div>

            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
}
