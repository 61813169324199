import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@chakra-ui/react';
import Android from '../images/coding.png';
import Content from '../images/content-marketing.png';
import Frontend from '../images/front-end.png';
import Backend from '../images/backend.png';
import Product from '../images/product.png';
import Design from '../images/design.png';
import Business from '../images/kpi.png';

const AvailableTracks = ({ item }) => {
  const navigate = useNavigate();
  const { title, description, slug } = item;

  const viewTrack = () => {
    navigate(`/skills/${slug}`);
  };

  const TrackIllustration = param => {
    switch (param) {
      case 'android-development':
        return (
          <img
            src={Android}
            width="64px"
            height={'64px'}
            alt="android-development"
          />
        );
      case 'frontend-development':
        return (
          <img
            src={Frontend}
            width="64px"
            height={'64px'}
            alt="frontend-development"
          />
        );
      case 'backend-development':
        return (
          <img
            src={Backend}
            width="64px"
            height={'64px'}
            alt="backend-development"
          />
        );
      case 'ui-ux-design':
        return (
          <img src={Design} width="64px" height={'64px'} alt="ui-ux-design" />
        );
      case 'content-marketing':
        return (
          <img
            src={Content}
            width="64px"
            height={'64px'}
            alt="content-marketing"
          />
        );
      case 'product-management':
        return (
          <img
            src={Product}
            width="64px"
            height={'64px'}
            alt="product-management"
          />
        );
      default:
        return (
          <img
            src={Business}
            width="64px"
            height={'64px'}
            alt="business-development"
          />
        );
    }
  };

  return (
    <Flex
      mb="24px"
      flexDirection={'column'}
      w="100%"
      p="24px 32px 32px"
      shadow="sm"
      border={'1px solid #e2e8f0'}
      justifyContent="space-between"
      _hover={{ shadow: 'lg' }}
      rounded="lg"
      onClick={viewTrack}
      cursor="pointer"
    >
      <div>
        <div style={{ marginBottom: '24px' }}>{TrackIllustration(slug)}</div>
        <Flex flexDirection={'column'}>
          <Text
            fontSize="lg"
            fontWeight="bold"
            mb="12px"
            lineHeight="32px"
            letterSpacing="0.01em"
            textColor={'gray.700'}
          >
            {' '}
            {title}{' '}
          </Text>
          <Text
            fontSize="md"
            fontWeight="normal"
            color="gray.600"
            noOfLines={3}
          >
            {description}
          </Text>
        </Flex>
      </div>

      <Flex alignItems={'flex-end'} mt="24px">
        <Button
          fontSize="xs"
          _hover={{ textDecoration: 'underline' }}
          _focus={{ outline: 'none' }}
          color={'brand.darkBlue'}
          variant="link"
          onClick={viewTrack}
          className="uppercase"
          style={{ letterSpacing: '0.1em' }}
        >
          Explore skill
        </Button>
      </Flex>
    </Flex>
  );
};

export default AvailableTracks;
