import React from 'react';
import {
  Box,
  Flex,
  Text,
  Avatar,
  Link,
  Heading,
  Skeleton,
} from '@chakra-ui/react';
import { Style } from 'react-style-tag';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth-service';
import Logos from '../data/logos.json';
import { BuildingOfficeIcon } from '@heroicons/react/20/solid';

export const MentorItem = ({ mentor }) => {
  const navigate = useNavigate();

  const viewMentorPage = event => {
    event.preventDefault();
    navigate(`/mentor/${mentor.username}`);
  };

  return (
    <>
      <Flex
        cursor="pointer"
        onClick={event => {
          event.preventDefault();
          navigate(`/mentor/${mentor.username}`);
        }}
        p="15px"
        color="#718096"
        borderRadius="lg"
        className="border border-gray-200"
        shadow="sm"
        flexDirection="column"
        justifyContent="space-between"
        _hover={{ shadow: 'md' }}
        fontSize="14px"
        minHeight="280px"
      >
        <Box>
          <Flex w="100%" mb="20px" justify="space-between">
            <Avatar
              color="white"
              name={`${mentor.firstname} ${mentor.lastname}`}
              src={AuthService.buildImageUrl(mentor.image)}
              size="lg"
            />
            <Box>
              {Logos[mentor.company] ? (
                <Avatar
                  w="25px"
                  h="25px"
                  src={Logos[mentor.company]}
                  alt={mentor.company}
                />
              ) : (
                <BuildingOfficeIcon className="w-6 h-6 text-slate-600 " />
              )}
              <Text fontSize="sm">{mentor.company}</Text>
            </Box>
          </Flex>

          <Flex direction="column">
            <Heading as="h4" mb="10px" fontSize="lg" color="#2d3748">
              {`${mentor.firstname} ${mentor.lastname}`}{' '}
            </Heading>
            <Text textColor={'gray.800'}>
              {mentor.current_role + ' at ' + mentor.company}
            </Text>
            <Box
              my={4}
              textColor={'gray.600'}
              overflow="hidden"
              maxHeight="63px"
              className="line-clamp"
              noOfLines={3}
            >
              {mentor.pitch}
            </Box>
          </Flex>
        </Box>

        <Link
          color="#ED8936"
          ml="auto"
          transition="all 0.3s"
          _hover={{
            textDecoration: 'none',
            color: '#DD6B20',
            transform: 'scale(1.02)',
          }}
          onClick={viewMentorPage}
        >
          View profile <i className="fas fa-arrow-right ml-2"></i>
        </Link>
      </Flex>
      <Style>
        {`
          .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
          }
        `}
      </Style>
    </>
  );
};

export const MentorItemSkeleton = () => {
  return (
    <>
      <Flex
        p="15px"
        borderRadius="5px"
        shadow="md"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="280px"
      >
        <Box>
          <Flex
            w="100%"
            mb="20px"
            justify="space-between"
            textTransform="uppercase"
          >
            <Skeleton w="3rem" h="3rem" rounded="50%"></Skeleton>
            <Flex direction="column">
              <Flex>
                <Skeleton w="20px" h="20px" rounded="50%"></Skeleton>
                <Skeleton ml="-5px" w="20px" h="20px" rounded="50%"></Skeleton>
              </Flex>
              <Skeleton mt="10px" height="10px"></Skeleton>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="50px"></Skeleton>
          </Flex>
        </Box>

        <Skeleton w={['70%']} ml="auto" mt="10px" height="20px"></Skeleton>
      </Flex>
    </>
  );
};
