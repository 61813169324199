import React from 'react';
import { MentorItem, MentorItemSkeleton } from './MentorItem';
import { SimpleGrid, Flex, Text } from '@chakra-ui/react';

const MentorList = ({ mentorsList, isLoading }) => {
  return (
    <div>
      <SimpleGrid
        mt="25px"
        columns={[1, 2, 3, 3]}
        justifyContent="space-evenly"
        spacing="20px"
        mb="20px"
        w="100%"
      >
        {isLoading && (
          <>
            <MentorItemSkeleton />
            <MentorItemSkeleton />
            <MentorItemSkeleton />
          </>
        )}

        {!isLoading &&
          mentorsList.length > 0 &&
          mentorsList.map((mentor, index) => (
            <MentorItem key={index} mentor={mentor} />
          ))}
      </SimpleGrid>
      {!isLoading && mentorsList.length < 1 && (
        <Flex boxShadow="md" borderRadius="5px" p="20px" w="100%">
          <Text fontWeight="bold" color="#718096">
            We couldn't find any mentors that match your search.
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default MentorList;
