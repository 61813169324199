import {API_BASE_URL, connection} from "./index"
import AuthService from "./auth-service";

const createBooking = async (payload) => {
    const token = AuthService.getAuthToken();
    if (token) {
        const response = await connection.post(API_BASE_URL + "bookings", { booking: payload }, {
            headers: {
                "Authorization": `token ${token}`
            }
        });

        return response.data;
    }
    const response = await connection.post(API_BASE_URL + "bookings", { booking: payload });

    return response.data;
}

const listBookings = async () => {
    const token = AuthService.getAuthToken();
    const response = await connection.get(API_BASE_URL + "bookings", {
        headers: {
            "Authorization": `token ${token}`
        }
    });

    return response.data;
}

const getBooking = async (hashCode) => {
    const token = AuthService.getAuthToken();
    const response = await connection.get(API_BASE_URL + `bookings/${hashCode}`, {
        headers: {
            "Authorization": `token ${token}`
        }
    });

    return response.data
}

const saveCoachingSession = async (payload, bookingId) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "bookings/" + bookingId + "/coaching_sessions",
        {session: payload}, {
            headers: {
                "Authorization": `token ${token}`
            }
        });
}

const listCoachingSession = async () => {

}

export default { 
    createBooking, 
    listBookings, 
    saveCoachingSession, 
    listCoachingSession, 
    getBooking 
}