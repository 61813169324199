import axios from 'axios'

export const API_BASE_URL = 'https://techstarta-api-yajc6.ondigitalocean.app/api/';
export const MEDIA_BASE_URL = 'https://storage.googleapis.com/talentmine/'
export const AUTH_TOKEN_KEY = 'auth_token';

export const connection = axios.create({
    baseURL: API_BASE_URL,
    responseType: "json",
})
