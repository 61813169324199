import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { ROLES } from "../utils/auth/permission-maps";
import useProfile from "../utils/auth/useProfile";
import authService from "../services/auth-service";

const ProfileSwitchHandler = () => {
    const user = JSON.parse(authService.getCurrentUser());
    const { profile } = useParams();
    const { switchProfile } = useProfile();

    if (profile === ROLES.mentor && user.is_mentor) {
        switchProfile(ROLES.mentor);
        return <Navigate replace to="/m/home" />
    } else {
        switchProfile(ROLES.learner);
        return <Navigate replace to="/home" />;
    }
}

export default ProfileSwitchHandler;