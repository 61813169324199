import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const getStripeCheckoutUrl = async (payload) => {
    const token = AuthService.getAuthToken();
    if (!token) {
        const response = await connection.post(API_BASE_URL + "stripe/checkout_sessions", payload);
        return response.data;
    }

    const response = await connection.post(API_BASE_URL + `stripe/checkout_sessions`, payload, {
        headers: {
            "Authorization": `token ${token}`
        }
    })

    return response.data;

}

export default {
    getCheckoutUrl: getStripeCheckoutUrl
}