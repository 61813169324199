import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useToast,
  Box,
  Flex,
  Avatar,
  Text,
  Stack,
  Skeleton,
  Button,
  Icon,
  Center,
  Tag,
  Spacer,
  VStack,
  SimpleGrid,
  Wrap,
  WrapItem,
  HStack,
} from '@chakra-ui/react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import AuthService from '../services/auth-service';
import BookingService from '../services/booking-service';
import { Mixpanel } from '../utils/Mixpanel';
import { FaLink, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import InclusionItem from './Membership/InclusionItem';
import { UserIcon } from '@heroicons/react/24/outline';
import { BoltIcon, BriefcaseIcon } from '@heroicons/react/24/solid';
import { UsersIcon } from '@heroicons/react/20/solid';

export const MentorProfile = ({ mentor }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const pricePerSession = mentor.price_per_session ?? 30;
  const priceWithoutDeal = parseInt(pricePerSession) + 5;
  const defaultNumberOfSessions = 3;
  const flutterwavePublicKey = 'FLWPUBK-4052eabb76d3bb619ea840a384e94ab6-X';
  const TECHSTARTA_LOGO_URL =
    'https://res.cloudinary.com/techstarta/image/upload/v1614841703/Techstarter_Icon_blue_background_pq6g3a.png';

  const [numberOfCoachingSessions, setNumberOfCoachingSessions] = useState(
    defaultNumberOfSessions
  );
  const defaultTotalAmount = pricePerSession * defaultNumberOfSessions;
  const user = JSON.parse(AuthService.getCurrentUser());

  const [amount, setAmount] = useState(defaultTotalAmount);
  const [paymentConfig, setPaymentConfig] = useState({
    public_key: flutterwavePublicKey,
    tx_ref: Date.now(),
    amount: defaultTotalAmount,
    currency: 'USD',
    payment_options: 'card, mobilemoney, ussd',
    customer: {
      email: user ? user.email : '',
      phonenumber: '',
      name: user ? user.firstname + ' ' + user.lastname : '',
    },
    customizations: {
      title: 'Techstarta Coaching Session',
      description: `Payment for ${numberOfCoachingSessions} coaching sessions with ${mentor.name}`,
      logo: TECHSTARTA_LOGO_URL,
    },
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState(user ? user.email : '');
  const [currentUser, setCurrentUser] = useState('');
  const [bookingInProgress, setBookingInProgress] = useState(false);

  useEffect(() => {
    const currentUser = JSON.parse(AuthService.getCurrentUser());
    setCurrentUser(currentUser);
    if (currentUser !== null) {
      setName(currentUser.firstname + ' ' + currentUser.lastname);
      setEmail(currentUser.email);
    }
  }, []);

  const handleNumberOfSessionChange = e => {
    const numberOfCoachingSessions = e.target.value;
    const totalAmount = numberOfCoachingSessions * pricePerSession;

    setNumberOfCoachingSessions(numberOfCoachingSessions);
    setAmount(totalAmount);
    setPaymentConfig({
      public_key: flutterwavePublicKey,
      tx_ref: Date.now(),
      amount: totalAmount,
      currency: 'USD',
      payment_options: 'card, mobilemoney, ussd',
      customer: {
        email: email,
        phonenumber: '',
        name: name,
      },
      customizations: {
        title: 'Techstarta Coaching Session',
        description: `Payment for ${numberOfCoachingSessions} coaching sessions with ${mentor.name}`,
        logo: TECHSTARTA_LOGO_URL,
      },
    });
  };

  const handleInputsChange = event => {
    event.preventDefault();
    if (event.target.name === 'name') {
      setName(event.target.value);
    } else if (event.target.name === 'email') {
      setEmail(event.target.value);
      setPaymentConfig({
        public_key: flutterwavePublicKey,
        tx_ref: Date.now(),
        amount: totalAmount,
        currency: 'USD',
        payment_options: 'card, mobilemoney, ussd',
        customer: {
          email: event.target.value,
          phonenumber: '',
          name: name,
        },
        customizations: {
          title: 'Techstarta Coaching Session',
          description: `Payment for ${numberOfCoachingSessions} coaching sessions with ${mentor.name}`,
          logo: TECHSTARTA_LOGO_URL,
        },
      });
    }
  };

  const totalAmount = numberOfCoachingSessions * pricePerSession;

  const handleFlutterwavePayment = useFlutterwave(paymentConfig);

  const createBooking = async booking => {
    try {
      const response = await BookingService.createBooking(booking);
      toast({
        title: 'Booking created',
        description: "We've notified your coach.",
        status: 'success',
        isClosable: true,
        duration: 3000,
      });
      navigate('/bookings');
    } catch (e) {
      toast({
        title: 'Booking failed.',
        description: "Something went wrong. We've been notified",
        status: 'error',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleBookNow = async event => {
    setBookingInProgress(true);
    event.preventDefault();
    Mixpanel.track('BookCoachingSession', { email, name });
    if (totalAmount > 0) {
      handleFlutterwavePayment({
        callback: async response => {
          const booking = await createBooking({
            transaction_id: response.transaction_id,
            customer_name: response.customer.name,
            customer_email: response.customer.email,
            currency: response.currency,
            number_of_sessions: numberOfCoachingSessions,
            payment_status: response.status,
            amount: response.amount,
            coach_username: mentor.username,
            payment_gateway: 'flutterwave',
            transaction_ref: response.tx_ref,
            payment_gateway_ref: response.flw_ref,
          });
          setBookingInProgress(false);
          closePaymentModal();
        },
        onClose: () => {},
      });
    } else {
      const booking = await createBooking({
        customer_email: email,
        customer_name: name,
        amount: 0,
        currency: 'USD',
        coach_username: mentor.username,
        number_of_sessions: numberOfCoachingSessions,
        payment_gateway: 'free',
        payment_status: 'successful',
        transaction_id: 'FREE',
      });
      setBookingInProgress(false);
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full xl:w-8/12">
            <Flex
              p={['10px', '10px', '20px', '20px']}
              flexWrap="wrap"
              rounded={'lg'}
              justify={['center', 'center', 'center', 'space-between']}
            >
              <Box mr={['0px', '10px', '15px', '20px']}>
                <Avatar
                  w="200px"
                  h="200px"
                  mb="25px"
                  fontSize="45px"
                  borderRadius="50%"
                  bgColor={'brand.darkBlue'}
                  icon={<UserIcon className="w-28 h-28" />}
                  name={mentor.firstname + ' ' + mentor.lastname}
                  src={AuthService.buildImageUrl(mentor.user.image)}
                  shadow="lg"
                />

                <Center>
                  <Stack
                    spacing="4"
                    isInline
                    textAlign="center"
                    mb="5px"
                    color="#ED8936"
                  >
                    {mentor.linkedin ? (
                      <a
                        href={mentor.linkedin}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Icon as={FaLinkedinIn} />
                      </a>
                    ) : null}
                    {mentor.twitter ? (
                      <a
                        href={
                          mentor.twitter.startsWith('http')
                            ? mentor.twitter
                            : `https://twitter.com/${mentor.twitter}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon as={FaTwitter} />
                      </a>
                    ) : null}
                    {mentor.website ? (
                      <a
                        href={mentor.website}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Icon as={FaLink} />
                      </a>
                    ) : null}
                  </Stack>
                </Center>
              </Box>

              <Flex
                fontSize="md"
                mt={['20px', '10px', '0px']}
                flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
                direction={['column']}
              >
                <Box
                  mb="25px"
                  textAlign={['center', 'start', 'center', 'start']}
                >
                  <Text color="#2d3748" fontSize="3xl" fontWeight="700">
                    {`${mentor.user.firstname} ${mentor.user.lastname}`}
                  </Text>
                  <Text textColor={'gray.700'}>
                    {mentor.current_role + ' at ' + mentor.company}
                  </Text>
                </Box>
                <Box>
                  <Text mb="5px" fontSize="md" fontWeight="700" color="#2d3748">
                    About
                  </Text>
                  <Text fontSize="md" align={'justify'} textColor={'gray.600'}>
                    {mentor.pitch}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </div>
          <div className="w-full xl:w-4/12 border rounded-lg p-4">
            <Flex
              flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
              direction={['column']}
            >
              <Box textColor={'gray.600'} pb={10}>
                <HStack pb={4} spacing={2}>
                  <UsersIcon className="w-5 h-5" />
                  <Text fontSize={'md'} fontWeight="semibold">
                    Get mentored by {mentor.user.firstname}
                  </Text>
                </HStack>
                <InclusionItem label="Access to premium challenges" />
                <InclusionItem label="Access to personalized expert feedback." />
                <InclusionItem label="Build your portfolio you are proud to share." />
                <InclusionItem label="Resume, LinkedIn & Portfolio review." />
              </Box>
              <Spacer height={'16'} />

              <Text fontSize={'sm'} pb="2" textColor={'gray.600'}>
                Upgrade to get mentored by{' '}
                <span className="font-semibold">{mentor.user.firstname}</span>
              </Text>
              <Button
                onClick={() => navigate('/membership')}
                leftIcon={<BoltIcon className="w-4 h-4" />}
                bgColor={'brand.darkBlue'}
                textColor={'white'}
                _hover={{ shadow: 'md' }}
                rounded={'full'}
              >
                Upgrade membership
              </Button>
            </Flex>
          </div>
          <div className="w-full">
            <SimpleGrid mt={4} columns={[1, 1, 1, 2]} spacing={'8'}>
              <Box shadow="sm" border={'1'} rounded={'lg'} mt="4" p={4}>
                <Text mb="4" fontSize="lg" fontWeight="700" color="#2d3748">
                  Experience
                </Text>
                <Flex
                  justifyContent={'space-between'}
                  border="1px"
                  borderColor={'gray.300'}
                  rounded={'md'}
                  p={4}
                >
                  <Flex>
                    <Flex
                      className={'bg-cyan-100'}
                      height={'10'}
                      width="10"
                      justify={'center'}
                      alignItems="center"
                      rounded={'md'}
                      mr="2"
                    >
                      <BriefcaseIcon className="h-6 w-6 text-cyan-500" />
                    </Flex>
                    <VStack alignItems={'flex-start'} spacing="-1">
                      <Text
                        fontSize={'md'}
                        fontWeight="semibold"
                        textColor={'brand.darkBlue'}
                      >
                        {mentor.current_role}
                      </Text>
                      <Text
                        fontSize={'sm'}
                        fontWeight={'semibold'}
                        textColor="gray.600"
                      >
                        {mentor.company}
                      </Text>
                    </VStack>
                  </Flex>
                  <Tag bg="gray.100" color="gray.600" fontSize={'sm'}>
                    Current
                  </Tag>
                </Flex>
              </Box>
              <Box shadow="sm" rounded={'lg'} mt="4" p={4}>
                <Text mb="5px" fontSize="lg" fontWeight="700" color="#2d3748">
                  Expertise
                </Text>
                {mentor.tracks.map(expertise => (
                  <Wrap pt={4}>
                    <WrapItem>
                      <Tag
                        bg="cyan.50"
                        rounded={'full'}
                        variant="outline"
                        color="cyan.900"
                        key={expertise.title}
                        mr="2"
                        mb="2"
                      >
                        {expertise.title}
                      </Tag>
                    </WrapItem>
                  </Wrap>
                ))}
              </Box>
            </SimpleGrid>
          </div>
        </div>
      </div>
    </>
  );
};

export const MentorProfileSkeleton = () => {
  return (
    <>
      <Flex
        p={['10px', '10px', '20px', '20px']}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Skeleton
            w="200px"
            h="200px"
            mb="25px"
            rounded="50%"
            shadow="lg"
          ></Skeleton>
          <Skeleton height="20px"></Skeleton>
        </Box>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Skeleton mb="10px" height="30px"></Skeleton>
            <Skeleton height="15px"></Skeleton>
          </Box>
          <Box>
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="100px"></Skeleton>
          </Box>
        </Flex>

        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <Box>
            <Skeleton mb="25px" height="50px"></Skeleton>
          </Box>
          <Box>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
          </Box>
          <Skeleton my="25px" height="50px"></Skeleton>

          <Skeleton height="50px"></Skeleton>
        </Flex>
      </Flex>
    </>
  );
};
