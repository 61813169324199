import React from "react";
import { Navigate } from "react-router-dom";

import AuthService from "../services/auth-service"
import { ROLES } from "../utils/auth/permission-maps";
import useProfile from "../utils/auth/useProfile";

const AlreadyAuthorized = ({Component}) => {
    const { getProfile } = useProfile()

    const token = AuthService.getAuthToken();
    const homeUrl = getProfile() === ROLES.mentor ? "/m/home" : "/home"
    
    if (token) {
        return <Navigate replace to={homeUrl} />
    }
    return <Component />
}

export default AlreadyAuthorized;
