import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Box, VStack, Button } from '@chakra-ui/react';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

export function SkillWithProgress({ skill }) {
  const navigate = useNavigate();
  return (
    <Flex
      border={'1px'}
      borderColor="gray.200"
      shadow={'sm'}
      rounded={'lg'}
      key={skill.slug}
      size="lg"
      direction={'column'}
      _hover={{ shadow: 'md' }}
      mb="2"
    >
      <Box p={4}>
        <Text lineHeight={'4'} fontWeight="semibold" textColor={'gray.600'}>
          {skill.title}
        </Text>
        <Text lineHeight={'4'} fontSize="sm" textColor={'gray.600'}>
          {skill.tier.label}
        </Text>
      </Box>
      <div className="grid grid-cols-3 divide-x items-center border-t border-b border-gray-200 bg-slate-50">
        <div className="text-center place-items-center">
          <VStack spacing={1} p={2}>
            <CheckBadgeIcon className="h-5 w-5 text-yellow-600" />
            <Text className="text-yellow-600">{skill.medals.gold}</Text>
          </VStack>
        </div>
        <div className="text-center  place-items-center">
          <VStack spacing={1} p={2}>
            <CheckBadgeIcon className="h-5 w-5 text-slate-500" />
            <Text className="text-slate-600">{skill.medals.silver}</Text>
          </VStack>
        </div>
        <div className="text-center place-items-center">
          <VStack spacing={1} p={2}>
            <CheckBadgeIcon className="h-5 w-5 text-yellow-700" />
            <Text className="text-yellow-700">{skill.medals.bronze}</Text>
          </VStack>
        </div>
      </div>
      <Flex p={4} justifyContent="space-between" alignItems={'center'}>
        <Text lineHeight={'4'} textColor={'gray.600'}>
          {skill.total_points} points
        </Text>
        <Button
          _hover={{ shadow: 'md' }}
          bgColor={'gray.700'}
          textColor="white"
          size={'sm'}
          rounded={'full'}
          onClick={() => navigate(`/skills/${skill.slug}/lessons`)}
        >
          Resume
        </Button>
      </Flex>
    </Flex>
  );
}
