import React from 'react';
import { Text, Flex, HStack } from '@chakra-ui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

const InclusionItem = ({ label }) => (
  <Flex fontSize="sm" mb="2" alignItems="center" textColor={'gray.700'}>
    <HStack spacing={2}>
      <CheckIcon className="w-5 h-5" />
      <Text>{label}</Text>
    </HStack>
  </Flex>
);

export default InclusionItem;
