import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

import DashboardNavbar from '../components/DashboardNavbar';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import { SubmissionForm } from '../components/SubmitSolutionItem';
import submissionsService from '../services/submissions-service.js';
import withRouter from '../utils/withRouter';
import trackService from '../services/track-service';
import SEO from '../components/Common/SEO';
import { Mixpanel } from '../utils/Mixpanel';
import MarkdownToHtml from '../components/ReactMarkdownItem';
import authService from '../services/auth-service';
import splitbee from '@splitbee/web';

const EditChallengeSubmission = () => {
  const { challengeId: slug } = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState({});
  const [submission, setSubmission] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  splitbee.track('Submit Challenge Page', {
    challenge: slug,
  });
  Mixpanel.track('Submit Challenge Page', {
    challenge: slug,
  });

  useEffect(() => {
    async function getCurrentChallenge() {
      try {
        setLoading(true);
        const response = await trackService.getChallenge(slug);
        setChallenge(response.challenge);
        Mixpanel.track('Submit Challenge Page', {
          challenge: response.challenge.title,
        });
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch this challenge, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
    getCurrentChallenge();
  }, [slug, toast]);

  const handleRequestMentorReview = async mentorId => {
    try {
      setLoading(true);
      const response = await submissionsService.requestMentorFeedback({
        submissionId: challenge.submission.id,
        mentorId,
      });
      if (response.status === 200) {
        setChallenge({
          ...challenge,
          submission: response.data.submission,
        });
        toast({
          title: 'Success!',
          description: 'Your request has been sent to the mentor',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to request mentor review, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <SEO
        title="Submit your work | Techstarta"
        url="http://techstarta.com/challenges"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Submit your work"
          hideBecomeAMentorButton={true}
        />
        <Flex ml={'8'} p={4}>
          <Button
            size={'sm'}
            textColor={'gray.600'}
            leftIcon={<ArrowLeftIcon className="w-4 h-4" />}
            variant={'outline'}
            colorScheme="gray"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Flex>
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-5/12 xl:pr-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="flex flex-wrap items-center text-xs px-2">
                  <Skeleton isLoaded={!loading} width={'full'}>
                    <Flex
                      flexDirection={'column'}
                      width="full"
                      className="sticky"
                      border="1px solid"
                      borderColor="gray.100"
                      shadow={'sm'}
                      borderRadius={'lg'}
                      bgColor="white"
                    >
                      <Image
                        roundedTop={'lg'}
                        src={challenge?.image}
                        objectFit="cover"
                      />
                      <Flex p={4} flexDirection="column">
                        <Heading
                          as={'h1'}
                          fontSize="md"
                          textColor={'gray.600'}
                          pb={4}
                        >
                          {challenge?.title}
                        </Heading>
                        <MarkdownToHtml
                          input={challenge?.description}
                          className="mt-2"
                        />
                      </Flex>
                    </Flex>
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-7/12 mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={'lg'}
                    rounded={'lg'}
                    flexDirection="column"
                    width={'full'}
                    p={4}
                  >
                    <SubmissionForm
                      slug={challenge.slug}
                      submission={challenge.submission}
                    />

                    {challenge.submission?.assigned_mentor != null ? (
                      <>
                        <Flex
                          flexDirection={'column'}
                          pt="8"
                          px={2}
                          mt={4}
                          bgColor="white"
                        >
                          <Box pb={4}>
                            <Text className="font-semibold text-stone-600">
                              Your Mentor
                            </Text>
                            <Text className="text-xs text-gray-600">
                              Your mentor will review your submission and
                              provide feedback.
                            </Text>
                          </Box>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb="2"
                          >
                            <Flex alignItems="center">
                              <Avatar
                                size="sm"
                                name={
                                  challenge?.submission.assigned_mentor
                                    ?.firstname +
                                  ' ' +
                                  challenge?.submission.assigned_mentor
                                    ?.lastname
                                }
                                src={authService.buildImageUrl(
                                  challenge?.submission.assigned_mentor?.image
                                )}
                              />
                              <Box>
                                <Text
                                  ml="2"
                                  fontSize="sm"
                                  fontWeight={'semibold'}
                                >
                                  {challenge?.submission.assigned_mentor
                                    ?.firstname +
                                    ' ' +
                                    challenge?.submission.assigned_mentor
                                      ?.lastname}
                                </Text>
                                <Text
                                  ml="2"
                                  fontSize="xs"
                                  textColor={'gray.600'}
                                >
                                  {challenge?.submission.assigned_mentor
                                    ?.current_role +
                                    ', ' +
                                    challenge?.submission.assigned_mentor
                                      ?.company}
                                </Text>
                              </Box>
                            </Flex>
                            <Button
                              size={'sm'}
                              textColor={'gray.600'}
                              leftIcon={<CheckCircleIcon className="w-4 h-4" />}
                              variant={'outline'}
                              colorScheme="gray"
                            >
                              Assigned
                            </Button>
                          </Flex>
                        </Flex>
                      </>
                    ) : (
                      <Flex flexDirection={'column'} pt="8" px={2}>
                        <Box pb={4}>
                          <Text className="font-semibold text-stone-600">
                            Available Mentors
                          </Text>
                          <Text className="text-xs text-gray-600">
                            One of these mentors will review your submission
                          </Text>
                        </Box>

                        {challenge?.track_detail?.mentors?.map(
                          (mentor, index) => (
                            <Flex
                              key={index}
                              alignItems="center"
                              justifyContent="space-between"
                              mb="2"
                            >
                              <Flex alignItems="center">
                                <Avatar
                                  size="sm"
                                  name={
                                    mentor?.firstname + ' ' + mentor?.lastname
                                  }
                                  src={authService.buildImageUrl(mentor?.image)}
                                />
                                <Box>
                                  <Text
                                    ml="2"
                                    fontSize="sm"
                                    fontWeight={'semibold'}
                                  >
                                    {mentor?.firstname} {mentor?.lastname}
                                  </Text>
                                  <Text ml="2" fontSize="xs">
                                    {mentor?.current_role}, {mentor?.company}
                                  </Text>
                                </Box>
                              </Flex>
                              <Button
                                size={'sm'}
                                fontSize={'sm'}
                                textColor={'gray.600'}
                                variant={'outline'}
                                colorScheme="gray"
                                value={mentor.id}
                                onClick={() =>
                                  handleRequestMentorReview(mentor.id)
                                }
                              >
                                Request
                              </Button>
                            </Flex>
                          )
                        )}
                      </Flex>
                    )}
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(EditChallengeSubmission);
