import React from 'react';
import AuthService from '../services/auth-service';
import AuthComponent from './AuthComponent.js';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { Button, HStack, Text } from '@chakra-ui/react';
import { BoltIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import useProfile from '../utils/auth/useProfile';
import { ROLES } from '../utils/auth/permission-maps';
import {
  CogIcon,
  CreditCardIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

const navigation = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DashboardNavbar = ({ title, url }) => {
  const user = JSON.parse(AuthService.getCurrentUser());
  const navigate = useNavigate();
  const { getProfile } = useProfile();

  const userNavigation = user
    ? [
        {
          name: 'Your profile',
          href:
            getProfile() === ROLES.mentor
              ? `/mentor/${user.username}`
              : `/profile/${user.username}`,
          icon: <UserCircleIcon className="w-4 h-4" />,
        },
        {
          name: 'Account settings',
          href: '/settings',
          icon: <CogIcon className="w-4 h-4" />,
        },
        {
          name: 'Billings',
          href: 'https://billing.stripe.com/p/login/eVaeVJ5NR6qz1gI9AA',
          icon: <CreditCardIcon className="w-4 h-4" />,
        },
        {
          name: 'Logout',
          href: '/logout',
          icon: <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>,
        },
      ]
    : [];

  return (
    <>
      {/* Navbar */}
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 xl:px-12">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <h1 className="text-sm text-gray-700 font-semibold uppercase">
                      {title}
                    </h1>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="hidden md:block">
                  {user ? (
                    <div className="ml-4 flex items-center md:ml-6">
                      <Button
                        onClick={() => navigate('/membership')}
                        leftIcon={<BoltIcon className="w-4 h-4" />}
                        mr={2}
                        fontSize="sm"
                        rounded="full"
                        bgColor={'brand.darkBlue'}
                        textColor={'white'}
                        _hover={{ shadow: 'md' }}
                      >
                        Upgrade
                      </Button>
                      <button
                        type="button"
                        onClick={() => navigate(`/profile/${user.username}`)}
                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View badges</span>
                        <CheckBadgeIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>

                      {/* Profile dropdown */}
                      <div>
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              {user.image ? (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={AuthService.buildImageUrl(user.image)}
                                  alt={user.username}
                                />
                              ) : (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src="https://res.cloudinary.com/techstarta/image/upload/v1673559875/user_m60knf.png"
                                  alt={user.username}
                                />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map(item => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      <HStack>
                                        {item.icon} <Text>{item.name}</Text>
                                      </HStack>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <div className="hidden md:block items-center">
                      <AuthComponent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default DashboardNavbar;
