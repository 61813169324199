import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import TrackList from '../components/TrackList';
import SEO from '../components/Common/SEO.js';
import choice from '../images/choice.png';
import profileService from '../services/profile-service.js';
import { SkillWithProgress } from '../components/Skill/SkillWithProgress.js';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const Skills = () => {
  const currrentUser = JSON.parse(localStorage.getItem('user'));
  const [currentUserSkills, setCurrentUserSkills] = useState([]);
  const [username, setUsername] = useState(currrentUser?.username);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await profileService.getProfile(username);
        setCurrentUserSkills(response.data.profile.skills);
      }
      setLoading(false);
    }
    fetchProfile();
  }, [username]);

  return (
    <>
      <SEO
        title="Learn the latest tech skills. | Techstarta"
        url="https://techstarta.com/skills"
        description="Get skilled in product management, product design, software engineering, data science, and more."
      />

      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Skills"
          url={'https://techstarta.com/skills'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 mt-10">
                <Skeleton isLoaded={!loading}>
                  {currentUserSkills.length > 0 ? (
                    <div>
                      <HStack>
                        <UserCircleIcon className="w-6 h-6 text-gray-600" />
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          mb="24px"
                          mt="60px"
                          textColor={'gray.600'}
                        >
                          My skills
                        </Text>
                      </HStack>

                      <SimpleGrid mt={8} columns={[1, 2, 2, 3]} spacing={4}>
                        {currentUserSkills.map((skill, index) => (
                          <SkillWithProgress key={index} skill={skill} />
                        ))}
                      </SimpleGrid>
                    </div>
                  ) : (
                    <Box
                      border="1px solid"
                      borderColor="gray.200"
                      rounded="lg"
                      p="32px 40px 48px"
                      bgColor="white"
                      mb={'4'}
                      shadow="sm"
                    >
                      <Flex justifyContent={'space-between'}>
                        <Box>
                          <Text
                            fontSize={'22px'}
                            textColor="gray.700"
                            fontWeight={'600'}
                          >
                            Not sure where to begin?
                          </Text>
                          <Text mt={'4'} fontSize={'18px'}>
                            Take a 2-minute quiz to find out which path fits you
                            best.
                          </Text>
                          <Box mt="4">
                            <Link _hover={{ shadow: 'md' }} href="/quiz">
                              <Button
                                _hover={{ shadow: 'md' }}
                                rounded={'full'}
                                bgColor={'brand.darkBlue'}
                                fontSize="sm"
                                textColor="white"
                              >
                                Take Quiz
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                        <Box>
                          <Image
                            src={choice}
                            alt="Find your tech career fit"
                            w={'20'}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  )}
                </Skeleton>

                <TrackList />
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Skills);
