import React from 'react';
import emoji from 'emoji-dictionary';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownToHtml = ({ input, classname }) => {
  const emojiSupport = text =>
    text.value.replace(/:\w+:/gi, name => emoji.getUnicode(name));

  return (
    <ReactMarkdown
      children={input}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      className={
        'prose md:prose-base sm:prose-base prose-slate prose-img:rounded-xl prose-headings:underline prose-a:text-orange-600 max-w-none ' +
        classname
      }
      linkTarget="_blank"
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={dracula}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        text: emojiSupport,
      }}
    />
  );
};

export default MarkdownToHtml;
