import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import MentorList from '../components/MentorList.js';
import MentorService from '../services/mentor-service';
import {
  Flex,
  Heading,
  Tag,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import splitbee from '@splitbee/web';

const Mentors = () => {
  Mixpanel.track('Viewed Mentors Page');
  splitbee.track('Viewed Mentors Page');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [mentorsList, setMentorsList] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    async function getListOfMentors() {
      try {
        setIsLoading(true);
        const response = await MentorService.getMentorList();
        setMentorsList(response.data);
        setFilteredResults(response.data);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch coaches, please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getListOfMentors();
  }, [toast]);

  const handleFilterByRole = event => {
    event.preventDefault();
    const role = event.target.value;
    if (role !== '') {
      const filteredMentors = mentorsList.filter(item =>
        item.current_role.contains(role)
      );
      setFilteredResults(filteredMentors);
    } else {
      setFilteredResults(mentorsList);
    }
  };

  return (
    <>
      <SEO
        title="Find a mentor. Learn from industry leaders | Techstarta"
        url="https://techstarta.com/mentors"
        description={`Find a mentor. Learn from industry leaders.`}
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Mentors"
          url={'https://techstarta.com/mentors'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 ">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-4 border-0">
                  <Flex
                    className="bg-gradient-to-bl from-cyan-400 to-gray-100  rounded-lg overflow-hidden border"
                    p={['10px', '30px', '40px', '40px']}
                    w="auto"
                    mt={['50px', '50px', '50px', '0px']}
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'space-between',
                    ]}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mb="10px"
                    rounded="lg"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="gray.700"
                      flexDirection="column"
                    >
                      <Heading textAlign="left" as={'h1'}>
                        Get mentored by industry leaders
                      </Heading>
                      <Wrap pt={2} pb={4}>
                        <WrapItem>
                          <Tag
                            colorScheme="cyan"
                            bgColor="cyan.100"
                            mb={['5px', '5px', '0px', '0px']}
                            mr={2}
                          >
                            Product Management
                          </Tag>
                        </WrapItem>
                        <WrapItem>
                          <Tag
                            colorScheme="orange"
                            bgColor="brown.100"
                            mb={['5px', '5px', '0px', '0px']}
                            mr={2}
                          >
                            Software Engineering
                          </Tag>
                        </WrapItem>
                        <WrapItem>
                          <Tag
                            colorScheme="gray"
                            bgColor="gray.100"
                            mb={['5px', '5px', '0px', '0px']}
                            mr={2}
                          >
                            Design
                          </Tag>
                        </WrapItem>
                      </Wrap>
                      <Text textAlign="left" fontSize="md">
                        Our mentors guide you with portfolio-worthy projects,
                        feedback, and tools for success.
                      </Text>
                    </Flex>
                  </Flex>
                  <MentorList
                    mentorsList={filteredResults}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Mentors);
