import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Badge, Button, HStack, Image } from '@chakra-ui/react';
import TechstartaLogoBlue from '../images/Techstarta Logo blue text - png.png';
import TechstartaLogoWhite from '../images/Techstarta-Logo-white-text.png';

import AuthService from '../services/auth-service';
import { FaFingerprint } from 'react-icons/fa';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHomeUser } from '@fortawesome/free-solid-svg-icons';
import useProfile from '../utils/auth/useProfile';
import { ROLES } from '../utils/auth/permission-maps';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const navigate = useNavigate();
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const { getProfile } = useProfile();
  const defaultHomeUrl = getProfile() === ROLES.mentor ? '/m/home' : '/home';

  const getNavColors = path => {
    const navColors = { default: '', hover: '' };
    const currentPath = window.location.pathname;
    if (currentPath.includes(path)) {
      navColors.default = 'text-orange-500';
      navColors.hover = 'text-orange-600 ';
    } else {
      navColors.default = 'text-gray-800 ';
      navColors.hover = 'text-gray-600 ';
    }

    return navColors;
  };

  return (
    <>
      <nav
        className={
          (props.transparent
            ? 'top-0 absolute z-50 w-full'
            : ' bg-white w-full shadow-sm') +
          ' fixed z-50 flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg'
        }
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className={
                (props.transparent ? 'text-white' : 'text-gray-800') +
                ' text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase'
              }
              href="/"
            >
              <HStack>
                <Image
                  src={
                    props.transparent ? TechstartaLogoWhite : TechstartaLogoBlue
                  }
                  alt="Techstarta logo"
                  height="40px"
                  width="auto"
                />
                <Badge color={'orange.500'} size={'sm'}>
                  Beta
                </Badge>
              </HStack>
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i
                className={
                  (props.transparent ? 'text-white' : 'text-gray-800') +
                  ' fas fa-bars'
                }
              ></i>
            </button>
          </div>
          {props.showMenu ? (
            <div
              className={
                'lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none' +
                (navbarOpen ? ' block rounded shadow-lg' : ' hidden')
              }
              id="example-navbar-warning"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <a
                    className={
                      props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : `${getNavColors('/skills').default} hover:${
                            getNavColors('/skills').hover
                          } text-sm py-3 px-3 font-bold block`
                    }
                    href="/skills"
                  >
                    <i
                      className={
                        (props.transparent
                          ? 'lg:text-gray-300 text-gray-500'
                          : 'text-gray-500') +
                        ' fab fa-company text-lg leading-lg '
                      }
                    />
                    <span className="inline-block ml-2">Start here</span>
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    className={
                      props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : `${getNavColors('/stories').default} hover:${
                            getNavColors('/stories').hover
                          } text-sm py-3 px-3 font-bold block`
                    }
                    href="/stories"
                  >
                    <i
                      className={
                        (props.transparent
                          ? 'lg:text-gray-300 text-gray-500'
                          : 'text-gray-500') +
                        ' fab fa-company text-lg leading-lg '
                      }
                    />
                    <span className="inline-block ml-2">Stories</span>
                  </a>
                </li>

                <li className="flex items-center">
                  <a
                    className={
                      props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : `${getNavColors('/membership').default} hover:${
                            getNavColors('/membership').hover
                          } text-sm py-3 px-3 font-bold block`
                    }
                    href="/membership"
                  >
                    <i
                      className={
                        (props.transparent
                          ? 'lg:text-gray-300 text-gray-500'
                          : 'text-gray-500') +
                        ' fab fa-company text-lg leading-lg '
                      }
                    />
                    <span className="inline-block ml-2">Membership</span>
                  </a>
                </li>

                <li className="flex items-center">
                  <a
                    className={
                      props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : `${getNavColors('/become-a-mentor').default} hover:${
                            getNavColors('/become-a-mentor').hover
                          } text-sm py-3 px-3 font-bold block`
                    }
                    href="/become-a-mentor"
                  >
                    <i
                      className={
                        (props.transparent
                          ? 'lg:text-gray-300 text-gray-500'
                          : 'text-gray-500') +
                        ' fab fa-company text-lg leading-lg '
                      }
                    />
                    <span className="inline-block ml-2">Become a mentor</span>
                  </a>
                </li>
                {props.currentUser ? (
                  ''
                ) : (
                  <React.Fragment>
                    <li className="flex items-center px-2 ">
                      <Button
                        rounded="full"
                        _focus={{ outline: 'none' }}
                        size="md"
                        textColor="gray.700"
                        fontWeight="bold"
                        fontSize="sm"
                        backgroundColor="white"
                        style={{ transition: 'all .15s ease' }}
                        variant="ghost"
                        onClick={() => navigate('/login')}
                        leftIcon={<FaFingerprint />}
                      >
                        {' '}
                        Login{' '}
                      </Button>
                    </li>
                    <li className="flex items-center px-2 sm:py-2">
                      <Button
                        rounded="full"
                        _focus={{ outline: 'none' }}
                        size="md"
                        fontWeight="bold"
                        fontSize="sm"
                        _hover={{ shadow: 'md' }}
                        colorScheme="orange"
                        bg="brand.darkBlue"
                        style={{ transition: 'all .15s ease' }}
                        variant="solid"
                        onClick={() => navigate('/get-started')}
                        rightIcon={
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="opacity-75 mr-2 text-sm"
                          />
                        }
                      >
                        Get started
                      </Button>
                    </li>
                  </React.Fragment>
                )}

                {props.currentUser && (
                  <Button
                    onClick={() => navigate(defaultHomeUrl)}
                    variant={'outline'}
                    rounded={'full'}
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faHomeUser}
                        className="opacity-75 mr-2 text-sm"
                      />
                    }
                  >
                    <Avatar
                      size="sm"
                      name={currentUser.firstname + ' ' + currentUser.lastname}
                      src={AuthService.buildImageUrl(currentUser.image)}
                    ></Avatar>
                  </Button>
                )}
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
    </>
  );
}
