const isValueValid = (value) => value.length > 1

const isEmailValid = (email) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email.toLowerCase()) === true && email.length > 1
}

const isPasswordValid = (password) => password.length >= 8

const isUrlValid = (url) => {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    return urlRegex.test(url.toLowerCase()) === true && url.length > 1
}

const isCalendlyLinkValid = (link) => {
    if (link.length === 0) {
        return true;
    }
    const linkRegex = /(http(s)?:\/\/.)?(www\.)?calendly\.com\/[a-zA-Z0-9@:%_\+.~#?&//=]/
    return linkRegex.test(link.toLowerCase()) === true && link.length > 1
}

export default { isValueValid, isEmailValid, isPasswordValid, isUrlValid, isCalendlyLinkValid }
