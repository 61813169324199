import React from 'react';
import { Navigate } from 'react-router-dom';
import splitbee from '@splitbee/web';
import authService from '../services/auth-service';

const Logout = () => {
  authService.logout();
  splitbee.reset();

  return <Navigate replace to={'/'} />;
};

export default Logout;
