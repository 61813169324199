import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  Text,
  Stack,
} from '@chakra-ui/react';
import TechstartaLogo from '../../images/Techstarta Logo blue text - png.png';

import { FaFingerprint, FaFire, FaUserPlus } from 'react-icons/fa';

const LoginModal = ({ isOpen, onClose, redirectTo }) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor={'gray.600'}>Login to continue</ModalHeader>
          <ModalCloseButton _focus={{ outline: 'none' }} />
          <ModalBody>
            <Flex direction="row">
              <Image src={TechstartaLogo} height="40px" width="auto" />
            </Flex>
            <Flex px="4" mt="4">
              <Text textColor={'gray.700'}>
                We are a community of learners, building careers in tech,
                solving problems, and sharing knowledge.
              </Text>
            </Flex>
            <Stack px="4" mt="4">
              <Button
                rounded={'full'}
                onClick={() => navigate(`/login?redirectTo=${redirectTo}`)}
                w={'full'}
                colorScheme="blue"
                _hover={{ shadow: 'md' }}
                bgColor="brand.darkBlue"
                variant="solid"
                leftIcon={<FaFingerprint />}
              >
                <Center>
                  <Text>Login</Text>
                </Center>
              </Button>
              <Button
                rounded={'full'}
                onClick={() => navigate('/get-started')}
                w={'full'}
                colorScheme={'gray'}
                variant="outline"
                leftIcon={<FaUserPlus />}
              >
                <Center>
                  <Text>Sign Up</Text>
                </Center>
              </Button>

              <Center pt="8" pb="8">
                <Text fontSize="sm">
                  We value your privacy and don’t ask for too much.
                </Text>
              </Center>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginModal;
