export const ROLES = {
    mentor: "MENTOR",
    learner: "LEARNER",
    employer: "EMPLOYER"
}

export const SCOPES = {
    canGradeChallenge: "can-grade-challenge",
    canCreateResource: "can-create-resource",
}

export const PERMISSIONS = {
    [ROLES.mentor]: [SCOPES.canGradeChallenge, SCOPES.canCreateResource]
}