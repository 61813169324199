import { useState } from "react";
import AuthService from "../../services/auth-service"
import { ROLES } from "./permission-maps";

const useProfile = () => {
    const authUser = JSON.parse(AuthService.getCurrentUser());
    const [profile, setProfile] = useState(ROLES.learner);

    const switchProfile = (toProfile) => {
        if (toProfile === ROLES.mentor && authUser.is_mentor) {
            localStorage.setItem('profile', toProfile);
        }
    
        if (toProfile === ROLES.employer && authUser.is_employer) {
            localStorage.setItem('profile', toProfile);
        }
    
        if (toProfile === ROLES.learner && authUser) {
            localStorage.setItem('profile', toProfile);
        }
    }

    const getProfile = () => localStorage.getItem('profile')

    const setProfileFromUser = (user) => {
        if (user.is_mentor) {
            localStorage.setItem('profile', ROLES.mentor);
            setProfile(ROLES.mentor);
        } else {
            localStorage.setItem('profile', ROLES.learner);
            setProfile(ROLES.learner);
        }
    }

    return { setProfileFromUser, switchProfile, getProfile }
}

export default useProfile;
