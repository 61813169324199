import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import AuthService from '../services/auth-service';
import siteCopy from '../data/siteCopy.json';

const AuthGate = ({ Component, NoAuthComponent }) => {
    const user = JSON.parse(AuthService.getCurrentUser());
    const toast = useToast();
    const location = useLocation();
    const pathname = location.pathname

    const token = AuthService.getAuthToken();
    if (!token) {
        if (NoAuthComponent !== undefined) {
            return <NoAuthComponent />
        }
        toast({
            title: siteCopy.loginRequired,
            status: "info",
            duration: 3000,
            isClosable: true,
        });
        return <Navigate replace to={`/login?redirectTo=${location.pathname}`} />
    }

    if (token && !user.verified_email && pathname !== '/verification_required') {
        return <Navigate replace to={`/verification_required`} />
    }

    if (token && user.verified_email && !pathname.startsWith('/onboarding') && !user.is_account_setup_completed) {
        return <Navigate replace to={`/onboarding`} />
    }
    
    return <Component user={user} />
}

export default AuthGate;
